<template></template>
<script>
export default {
	name: 'AutoLogout',
	data() {
		return {
			timeout: null,
			timer: 2 * 60 * 1000, // 2 minutes popup before logout
			maxSessionDuration: 2 * 60 * 60 * 1000, // 2 hours
			title: document.title,
		}
	},
	watch: {
		$route() {
			if (this.myUser) {
				this.countDown()
			}
		},
	},
	mounted() {
		if (this.myUser) {
			const lastLoginTime = localStorage.getItem('lastLoginTime') || Date.now()
			localStorage.setItem('lastLoginTime', lastLoginTime)
			this.checkSessionExpiration()
			this.countDown()
		}
	},
	methods: {
		countDown() {
			if (this.myUser) {
				clearTimeout(this.timeout)
				let timerInterval

				// Auto-logout after 1.5 hours of inactivity
				this.timeout = setTimeout(() => {
					var original = document.title
					var interval = setInterval(this.flashTitle, 1000, original)

					this.$swal
						.fire({
							title: 'Automatisk log ud',
							html: "På grund af inaktivitet vil du snart blive logget ud om <strong></strong> minutter.<br /><br/>Tryk på ''forlæng'' for at forblive logget ind.",
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#3085d6',
							cancelButtonColor: '#d33',
							cancelButtonText: 'Log ud',
							confirmButtonText: 'Forlæng',
							timer: this.timer,
							timerProgressBar: true,
							allowOutsideClick: false,
							allowOutsideEscapeKey: false,
							didOpen: () => {
								const content = this.$swal.getHtmlContainer()
								timerInterval = setInterval(() => {
									content.querySelector('strong').textContent =
										this.millisToMinutesAndSeconds(this.$swal.getTimerLeft())
								}, 100)
							},
						})
						.then(async (result) => {
							clearInterval(interval)
							this.setTitle(original)
							if (result.isConfirmed) {
								localStorage.setItem('lastLoginTime', Date.now())
								this.countDown()
							} else if (result.isDismissed) {
								this.logOut()
							}
						})
					this.signOut()
				}, 1.5 * 60 * 60 * 1000) // 1.5 hours of inactivity
			}
		},
		checkSessionExpiration() {
			const lastLoginTime = parseInt(localStorage.getItem('lastLoginTime'), 10)
			const now = Date.now()

			if (now - lastLoginTime > this.maxSessionDuration) {
				console.log('Session expired after 2 hours')
				this.logOut()
			}
		},
		async signOut() {
			this.timeout = setTimeout(() => {
				this.$store.dispatch('auth/signOutAction')
				this.$router.push({ name: 'Login' })
			}, this.timer)
		},
		async logOut() {
			clearTimeout(this.timeout)
			localStorage.removeItem('lastLoginTime')
			this.$store.dispatch('auth/signOutAction')
			this.$router.push({ name: 'Login' })
		},
		flashTitle(original) {
			document.title = document.title === original ? '\u200E' : original
		},
		setTitle(original) {
			document.title = original
		},
		millisToMinutesAndSeconds(millis) {
			const minutes = Math.floor(millis / 60000)
			const seconds = ((millis % 60000) / 1000).toFixed(0)
			return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
		},
	},
	computed: {
		myUser() {
			return this.$store.getters['auth/user']
		},
	},
}
</script>
